body {
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--primary-ff);
  font-weight: regular;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: var(--primary-ff-mono);
}

.p-sidebar-top {
  /* fix primereact sidebar top:
   * Fixed height is causing the overlay buttons to stay
   * outside the container and therefore showed up when the
   * sidebar is hidden.
   * https://www.primefaces.org/primereact/#/sidebar
   */
  height: auto !important;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

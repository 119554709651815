.Link {
  color: rgb(89, 169, 201);
}

.Link:hover {
  color: rgb(79, 186, 228);
  text-decoration: underline;
}

.LinkUnderlined {
  text-decoration: underline;
}

.checkbox {
  margin-right: 0.5em !important;
}

.hideBackground {
  background-color: rgba(85, 85, 85, 0) !important;
  /* -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear; */
}

.childrenWider {
  max-width: 500px !important;
}

.childrenWiderP {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  line-height: 1.4em;
  text-align: left;
}

.tryAgainBtn {
  width: 100%;
  font-size: 1.3em !important;
  color: #fff;
  background-color: rgba(89, 169, 201, 0.3) !important;
  margin-bottom: 0.8em !important;
}

.tryAgainBtn:hover {
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  background-color: rgba(89, 169, 201, 0.7) !important;
}

.headerExtra {
  margin-bottom: 2em !important;
}

/* do not remove this empty class */
.registerFormContainer {
}

.registerFormContainer :global(.p-dialog) {
  max-width: 700px;
  width: 90%;
}

.registerFormContainer :global(.p-dialog-titlebar) {
  background-color: tomato !important;
}

.fadeIn {
  visibility: hidden;
  opacity: 0;
  height: 0;
  transition: visibility 0s, height 0.5s linear, opacity 0.5s linear;
}

.fadeInShow {
  visibility: visible;
  height: 120px;
  opacity: 1;
}

.pFloatLabel label {
  color: #fff !important;
  caret-color: #fff !important;
}

.selectPlus {
  font-size: 1.3em !important;
  font-weight: bold !important;
  border-width: 2px !important;
  border-color: #fff !important;
  padding-top: 0.85em !important;
  padding-bottom: 0.5em !important;
}

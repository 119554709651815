.bgPrimary {
  background-color: var(--primary-color);
}

.textPrimary {
  color: var(--primary-color);
}

.fullWidth {
  width: 100%;
}

.loginBtn {
  font-size: 1.4em !important;
}

.pCenteredFFF {
  text-align: center;
  margin: 0;
}

.pCenteredFFFBig {
  composes: pCenteredFFF;
  font-size: 1.8em !important;
}
@media (max-width: 768px) {
  .pCenteredFFFBig {
    font-size: calc(
      1.2em + (1.51 - 1.2) * ((100vw - 21em) / (35 - 21))
    ) !important;
  }
}

.pCenteredFFFMid {
  text-align: center;
  font-size: 1.3em;
  margin: 0.9em 0;
}

.pLeftFFFMid {
  text-align: left;
  font-size: 1.3em;
  margin: 0.9em 0;
}

@media (max-width: 768px) {
  .pCenteredFFFMid,
  .pLeftFFFMid {
    font-size: calc(1em + (1.155 - 1) * ((100vw - 21em) / (35 - 21)));
  }
}

.root {
  display: grid;
  place-items: center;
  width: 100vw;
  height: 100vh;
  background-image: url('./SignUpSignIn-small.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: white;
}

/* the gray block */
.container {
  background-color: rgba(85, 85, 85, 0.7);
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  display: grid;
  justify-items: center;
  align-content: start;
  max-height: 85vh;
  padding: 3em 0;
  overflow: auto;
  box-sizing: content-box;
  width: 95vw !important;
  max-width: 720px;
}

@media (max-width: 470px) {
  .container input {
    font-size: calc(
      0.9em + (1.6 - 0.9) * ((100vw - 21em) / (35 - 21))
    ) !important;
    padding-top: 20px !important;
    padding-bottom: 22px !important;
  }

  /* select any input that are placed immediately after a svg  */
  .container svg + input {
    padding-left: calc(15%) !important;
  }

  /* This selector is pretty specific cause it was
the only way to override the component inline style */
  .children > div > form > div > div > div > svg,
  .children > div > form > div > div > svg {
    font-size: calc(
      2.2em + (4 - 2.2) * ((100vw - 21em) / (35 - 21))
    ) !important;
  }

  form div:nth-child(7) {
    grid-template-columns: 0.2fr 1fr;

    /* background: red !important;
    color: red !important; */
  }

  form div:nth-child(7) svg + input {
    padding-left: calc(25%) !important;
  }
}

/* allow long dropdown labels to be collapsed
on narrowed widths. The selector is pretty specific
cause it was the only way I can find to override
the primereact styling */
.children > div > form > div > div > label {
  white-space: normal !important;
  padding-right: 0 !important;
}

@media (max-width: 470px) {
  .children > div > form > div > div > label,
  .children > div > form > div > div > div > label {
    padding-right: 0;
    font-size: calc(1em + (1.6 - 1) * ((100vw - 21em) / (35 - 21))) !important;
  }
}

.header {
  text-align: center;
  margin-bottom: 3.5em;
  width: 90%;
  max-width: 463px;
}

@media (max-width: 768px) {
  .header {
    /* max 3.5em */
    /* min 1.5em */
    margin-bottom: calc(7.5%);
  }
}

.children {
  width: 90%;
  max-width: 400px;
}

.logo {
  width: 78%;
}

.connectBabbelConversifiLogo {
  width: 90%;
}

a {
  color: #bbbbff;
}

/*
$local-font-name:               "lato-regular";
$local-font-svg-id:             "latoregular";
$local-font-name-light:         "lato-light";
$local-font-svg-id-light:       "latolight";
$local-font-name-black:         "lato-black";
$local-font-svg-id-black:       "latoblack";
$local-font-name-bold:          "lato-bold";
$local-font-svg-id-bold:        "latobold";
$local-font-name-italic:        "lato-italic";
$local-font-svg-id-italic:      "latoitalic";
$local-font-name-bold-italic:   "lato-bolditalic";
$local-font-svg-id-bold-italic: "latobold-italic";

$font-family-base:              "Lato", Helvetica, Arial, sans-serif;
$font-family-demo:              "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-monospace:          Monaco, Menlo, Consolas, "Courier New", monospace;
*/

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-black.eot');
  src: url('../fonts/lato/lato-black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/lato-black.woff') format('woff'),
    url('../fonts/lato/lato-black.ttf') format('truetype'),
    url('../fonts/lato/lato-black.svg#latoblack') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-bold.eot');
  src: url('../fonts/lato/lato-bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/lato-bold.woff') format('woff'),
    url('../fonts/lato/lato-bold.ttf') format('truetype'),
    url('../fonts/lato/lato-bold.svg#latobold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-bolditalic.eot');
  src: url('../fonts/lato/lato-bolditalic.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/lato/lato-bolditalic.woff') format('woff'),
    url('../fonts/lato/lato-bolditalic.ttf') format('truetype'),
    url('../fonts/lato/lato-bolditalic.svg#latobold-italic') format('svg');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-italic.eot');
  src: url('../fonts/lato/lato-italic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/lato-italic.woff') format('woff'),
    url('../fonts/lato/lato-italic.ttf') format('truetype'),
    url('../fonts/lato/lato-italic.svg#latoitalic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-light.eot');
  src: url('../fonts/lato/lato-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/lato-light.woff') format('woff'),
    url('../fonts/lato/lato-light.ttf') format('truetype'),
    url('../fonts/lato/lato-light.svg#latolight') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato/lato-regular.eot');
  src: url('../fonts/lato/lato-regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/lato/lato-regular.woff') format('woff'),
    url('../fonts/lato/lato-regular.ttf') format('truetype'),
    url('../fonts/lato/lato-regular.svg#latoregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
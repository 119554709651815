.root {
  min-height: 100vh;
  background-image: linear-gradient(
      rgba(89, 169, 201, 0.6),
      rgba(19, 106, 138, 0.9)
    ),
    url('../assets/images/Admin-small.jpg');
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  overflow: auto;
}

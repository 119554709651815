/* In order to override to 'colors.css' rules, for the instructor report
  we need to use another palette of colors, so we will override the rules
  with this css rules for this Instructor app.
*/

.instructor-portal .p-tabmenu .p-tabmenu-nav {
  border: none;
}

.instructor-portal .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-bottom: none
}

.instructor-portal .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  font-weight: 700;
  margin-right: 0px;
  border-color: var(--primary-color);
}

.instructor-portal .p-tabmenuitem .p-highlight {
  border: 2px solid;
  border-color: var(--primary-color);
  border-bottom: none;
}

.instructor-portal .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus,
.instructor-portal .p-tabmenu .p-highlight>a:focus {
  box-shadow: 0 0 0 0 transparent !important;
}

.instructor-portal .p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  border-color: var(--primary-color);
}

.instructor-portal .p-button,
.instructor-portal .p-button.p-datepicker-trigger {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.instructor-portal .p-button.p-button-plain.p-button-icon-only {
  background-color: transparent;
  border-color: transparent;
}

.instructor-portal .p-button.p-button-info.p-button-outlined {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}

.instructor-portal .p-button.p-button-info.p-button-outlined:enabled:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.instructor-portal .p-button:enabled:hover {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
  box-shadow: 0 0 0 0.2rem var(--primary-color-light);
}

.p-button.p-button-secondary {
  background-color: var(--secondary-color-light);
}

.p-button.p-button-secondary:enabled:hover {
  box-shadow: 0 0 0 0.2rem var(--secondary-color-light);
  background-color: var(--secondary-color-light);
}


.instructor-portal .instructor-link {
  text-decoration: none;
  color: var(--primary-color-light);
  font-weight: 700;
}

.instructor-portal .instructor-link:hover {
  text-decoration: underline;
}

.instructor-portal .p-datatable thead.p-datatable-thead tr th {
  text-align: center;
  background-color: var(--secondary-color-light);
  color: white;
}

.instructor-portal .p-datatable .p-datatable-tbody>tr>td {
  text-align: center;
}

.templateContainer {
  max-height: 85vh;
  padding: 0 !important;
}

.header {
  margin: 3em 0 2em 0;
}

.content {
  margin-bottom: 2em;
}

.step1Container {
  box-sizing: border-box !important;

  /* animation credits to https://davidwalsh.name/css-slide */
  overflow-y: hidden;
  max-height: 500px; /* approximate max height */
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  padding: 0 5px;
}

.step1Container.closed {
  max-height: 0;
}

.phoneIsPossible {
  color: rgb(69, 182, 244);
}

.phoneIsValid {
  color: rgb(59, 240, 59);
}

.pMid {
  text-align: left;
  font-size: 1.3em;
  line-height: 1.4em;
  margin: 0.9em 0;
}

@media (max-width: 768px) {
  .pMid {
    font-size: calc(1em + (1.155 - 1) * ((100vw - 21em) / (35 - 21)));
  }
}

.errorDialog :global(.p-dialog-titlebar) {
  background-color: tomato !important;
}

.skipP {
  text-align: center;
}

.skipA {
  font-size: 1.2em;
  text-decoration: underline;
  color: rgb(105, 203, 255);
}

.p-button,
.p-checkbox,
.p-togglebutton.p-button.p-highlight,
.p-checkbox-box.p-highlight {
  background-color: var(--primary-color);
  opacity: 1;
  border-color: var(--primary-color);
  color: var(--white-color);
}

.p-button:enabled:hover,
.p-checkbox:enabled:hover,
.p-togglebutton.p-button.p-highlight:hover {
  background-color: var(--primary-color);
  opacity: 0.9;
  border-color: var(--primary-color);
  color: var(--white-color);
}

.p-button.p-button-outlined {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background-color: var(--white-color);
}

.p-button.p-button-outlined:enabled:hover {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.p-button.p-button-info.p-button-outlined {
  color: var(--primary-color);
}

.p-button.p-button-info.p-button-outlined:enabled:hover {
  color: var(--primary-color);
}

.p-button.p-button-info:enabled:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-color-light);
}

.p-selectbutton.p-buttonset .p-button {
  color: var(--gray-color) !important;
  border-color: var(--light-gray-color) !important;
  background-color: var(--white-color) !important;
}

.p-tabview-selected.p-highlight a {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: 0 0 0 0.2rem var(--primary-color-light);
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: var(--primary-color) !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--primary-color) !important;
}

.p-sortable-column.p-highlight:focus {
  outline-color: var(--primary-color-light) !important;
}

.p-sortable-column.p-highlight span {
  color: var(--primary-color) !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus,
.p-tabmenu .p-highlight>a:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-color-light);
}

.p-treetable-tbody tr:focus {
  outline-color: var(--primary-color-light) !important;
}

.p-treetable .p-treetable-tbody>tr>td .p-treetable-toggler:focus {
  box-shadow: 0 0 0 0.2rem var(--primary-color-light) !important;
}

.p-inputtext:enabled:hover,
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-inputtext:enabled:focus,
.p-dropdown:not(.p-disabled):hover {
  border-color: var(--primary-color);
}

.p-selectbutton.p-buttonset .p-button.p-highlight {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.p-datatable-loading-overlay.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.p-inline-message.p-inline-message-success {
  background-color: var(--primary-color) !important;
  color: var(--white-color) !important;
}

.p-inline-message.p-inline-message-error {
  background-color: var(--error-color) !important;
  color: var(--white-color) !important;
}

.logo {
  width: 12rem;
  cursor: pointer;
}

.menuIcon {
  color: white;
}

.menuItemLabel {
  width: 100%;
  text-align: start;
}

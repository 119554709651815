.textinput {
  /** all is "important" here because we're overriding styles from primereact theme */
  font-size: 1.3em !important;
  font-weight: bold !important;
  border-width: 2px !important;
  border-color: #fff !important;
  padding-top: 0.85em !important;
  padding-bottom: 0.85em !important;
  caret-color: white;
}

.container {
  width: 100%;
  margin-bottom: 1.8em;
}

.optInCheckbox {
  color: white !important;
}

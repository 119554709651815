:root {
  /* primary font family */
  --primary-ff: 'Lato', Helvetica, Arial, sans-serif;
  --font-family: 'Lato', Helvetica, Arial, sans-serif;
  --primary-ff-mono: Monaco, Menlo, Consolas, 'Courier New', monospace;
  --primary-color: rgb(89, 169, 201);
  --primary-color-light: rgb(122, 179, 202);
  --primary-color-dark: rgb(19, 106, 138);
  --secondary-color: rgb(34, 74, 114);
  --secondary-color-light: rgb(78, 110, 142);
  --secondary-color-dark: rgb(27, 59, 91);
  --light-gray-color: lightgray;
  --gray-color: gray;
  --black-color: black;
  --white-color: white;
  --error-color: #F5724E;

  /* some colors */
  --royale: linear-gradient(to top, #243b55, #141e30);
  --turquoise: linear-gradient(to top, #267871, #136a8a);
  --malibu: linear-gradient(to top, #4facfe 0%, #00f2fe 100%);
  --partybliss: linear-gradient(to top, #4481eb 0%, #04befe 100%);
  --rivercity: linear-gradient(to top, #4481eb 0%, #04befe 100%);
  --blueskies: linear-gradient(to bottom, #56ccf2, #2f80ed);
}
